import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'lib-icon',
  templateUrl: './icon.component.html',
  styles: ['@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css";']
})
export class IconComponent implements OnInit, OnChanges {
  @Input() svgLocation: string;
  @Input() altText: string;
  @Input() grayScale: string;
  @Input() svgHeight: number;
  @Input() svgWidth: number;

  @Input() iconName: string;
  @Input() iconSize: string;
  @Input() iconColor: string;

  @Input() badgeAmount: string;
  @Input() badgeColor: string;
  @Input() badgeLeft: string;
  @Input() badgeAbove: string;

  grayScaleStyle: string;

  ngOnInit() {
    this.grayScaleStyle = 'grayscale(' + this.grayScale + ')';
  }

  ngOnChanges() {
    this.grayScaleStyle = 'grayscale(' + this.grayScale + ')';
  }
}
