import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lib-menu-template',
  templateUrl: './menu-template.component.html',
  styles: []
})
export class MenuTemplateComponent implements OnInit {
  @Input() categories = [
    {
      id: 0,
      name: 'Classic Pizza',
      description: 'Neapolitan Pizza with fresh mozzarella and crushed tomatoes',
      products: [
        {
          id: 0,
          basecost: 6.95,
          choices: [
            {
              cost: 0,
              name: 'Crushed tomato',
              quantity: 1
            },
            {
              cost: 0,
              name: 'Mozzarella',
              quantity: 1
            },
            {
              cost: 0,
              name: 'Basil',
              quantity: 1
            }
          ],
          name: 'Margherita',
          specialinstructions: '',
          totalcost: 6.95,
          quantity: 1
        },
        {
          id: 1,
          basecost: 6.95,
          choices: [
            {
              cost: 0,
              name: 'Crushed tomato',
              quantity: 1
            },
            {
              cost: 0,
              name: 'mozzarella',
              quantity: 1
            },
            {
              cost: 0,
              name: 'oregano',
              quantity: 1
            }
          ],
          name: 'Napoli',
          specialinstructions: '',
          totalcost: 6.95,
          quantity: 1
        },
        {
          id: 2,
          basecost: 10.2,
          choices: [
            {
              cost: 0,
              name: 'Crushed tomato',
              quantity: 1
            },
            {
              cost: 0,
              name: 'mozzarella di bufala',
              quantity: 1
            },
            {
              cost: 0,
              name: 'basil',
              quantity: 1
            }
          ],
          name: 'Margherita with Mozzarella Di Bufala',
          specialinstructions: '',
          totalcost: 10.2,
          quantity: 1
        },
        {
          id: 3,
          basecost: 11.95,
          choices: [
            {
              cost: 0,
              name: 'Crushed tomato',
              quantity: 1
            },
            {
              cost: 0,
              name: 'mozzarella di bufala',
              quantity: 1
            },
            {
              cost: 0,
              name: 'Mt. Vesuvio tomato',
              quantity: 1
            },
            {
              cost: 0,
              name: 'Basil',
              quantity: 1
            }
          ],
          name: 'Margherita Extra',
          specialinstructions: '',
          totalcost: 11.95,
          quantity: 1
        }
      ]
    },
    {
      id: 1,
      name: 'Pizza Bianco',
      description: ''
    },
    {
      id: 2,
      name: 'Calzones',
      description: ''
    },
    {
      id: 3,
      name: 'Antipastis',
      description: ''
    },
    {
      id: 4,
      name: 'Salads',
      description: ''
    },
    {
      id: 5,
      name: 'Desserts',
      description: ''
    },
    {
      id: 6,
      name: 'Beverages',
      description: ''
    }
  ];
  @Input() text = 'Quick Add';
  catStrings: string[] = [];
  selectedCat = 0;

  @Input() links = [
    {
      text: 'home',
      url: 'https://www.google.com'
    },
    {
      text: 'locations',
      url: 'https://stackoverflow.com'
    },
    {
      text: 'menu',
      url: 'https://getbootstrap.com'
    },
    {
      text: 'Sign in',
      url: 'https://getbootstrap.com'
    }
  ];

  @Input() orderInfoTop = true;
  @Input() location = {
    name: 'Demo Location',
    streetaddress: '938 Dennison Ave.',
    city: 'Columbus',
    state: 'OH',
    zip: '43201',
    phone: '614-826-2673'
  };
  @Input() menuItem = 'CLASSIC PIZZAS';
  @Input() basket = {
    deliverymode: 'pickup', // can be 'pickup', 'dispatch', 'delivery'
    products: [
      {
        basecost: 10,
        choices: [
          {
            cost: 0,
            name: 'Mozzarella Cheese',
            quantity: 1
          },
          {
            cost: 1,
            name: 'Pepperoni',
            quantity: 1
          },
          {
            cost: 0,
            name: 'Red Onion',
            quantity: 1
          },
          {
            cost: 0,
            name: 'Portabella Mushroom',
            quantity: 2
          }
        ],
        name: 'Large Pizza',
        specialinstructions: 'Mushrooms only on half',
        totalcost: 11
      }
    ]
  };

  ngOnInit() {
    for (const cat of this.categories) {
      this.catStrings.push(cat.name);
    }
  }

  addToBag(product: any) {
    // TODO
    console.log('Add to bag: ' + product.name);
  }

  customize(product: any) {
    // TODO
    console.log('Customize: ' + product.name);
  }
}
