import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormArray, FormControl, UntypedFormGroup } from '@angular/forms';

@Pipe({
  name: 'getArrayFromFormGroup',
  pure: false
})
export class GetArrayFromFormGroupPipe implements PipeTransform {
  transform(formGroup: UntypedFormGroup, controlName: string): UntypedFormArray {
    return formGroup.get(controlName) as UntypedFormArray;
  }
}
