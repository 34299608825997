<ng-container *ngIf="choice == 'login'">
  <div>
    <lib-single-text-field hasLabel="true" label="Email Address" inputMode="email"></lib-single-text-field>
    <lib-single-text-field hasLabel="true" label="Password"></lib-single-text-field>
  </div>
</ng-container>
<ng-container *ngIf="choice == 'createAccount'">
  <div>
    <lib-single-text-field hasLabel="true" label="First Name"></lib-single-text-field>
    <lib-single-text-field hasLabel="true" label="Last Name"></lib-single-text-field>
    <lib-single-text-field hasLabel="true" label="Address"></lib-single-text-field>
    <lib-single-text-field hasLabel="true" label="City"></lib-single-text-field>
    <lib-single-text-field hasLabel="true" label="Zip Code" inputMode="numeric"></lib-single-text-field>
    <lib-single-text-field hasLabel="true" label="Phone Number" inputMode="tel"></lib-single-text-field>
  </div>
</ng-container>
<ng-container *ngIf="choice == 'userInfo'">
  <div>
    <lib-single-text-field hasLabel="true" label="First Name"></lib-single-text-field>
    <lib-single-text-field hasLabel="true" label="Last Name"></lib-single-text-field>
    <lib-single-text-field hasLabel="true" label="Email" inputMode="email"></lib-single-text-field>
    <lib-single-text-field hasLabel="true" label="Phone Number" inputMode="tel"></lib-single-text-field>
  </div>
</ng-container>
<ng-container *ngIf="choice == 'creditCardInfo'">
  <div>
    <lib-single-text-field hasLabel="true" label="Name on Card"></lib-single-text-field>
    <lib-single-text-field hasLabel="true" label="Card Number" inputMode="numeric"></lib-single-text-field>
    <lib-single-text-field hasLabel="true" label="CVV" inputMode="numeric"></lib-single-text-field>
    <lib-single-text-field hasLabel="true" label="Expiration Date" inputMode="numeric"></lib-single-text-field>
    <lib-single-text-field hasLabel="true" label="Billing ZIP" inputMode="numeric"></lib-single-text-field>
  </div>
</ng-container>
