import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Pipe({
  name: 'getControlFromFormGroup',
  pure: false
})
export class GetControlFromFormGroupPipe implements PipeTransform {
  transform(formGroup: UntypedFormGroup, controlName: string): UntypedFormControl {
    return formGroup.get(controlName) as UntypedFormControl;
  }
}
