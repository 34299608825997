import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Pipe({
  name: 'formGroupHasControl',
  pure: false
})
export class FormGroupHasControlPipe implements PipeTransform {
  transform(formGroup: UntypedFormGroup, controlName: string): boolean {
    return !!formGroup.get(controlName);
  }
}
