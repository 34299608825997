import { NgModule } from '@angular/core';
import { ChepriModulesComponent } from './chepri-modules.component';
import { AuthNavModule } from './auth-nav/auth-nav.module';
import { BagModule } from './bag/bag.module';
import { BagItemModule } from './bag-item/bag-item.module';
import { ButtonModule } from './button/button.module';
import { CartNavModule } from './cart-nav/cart-nav.module';
import { HeaderModule } from './header/header.module';
import { IconModule } from './icon/icon.module';
import { IncrementorModule } from './incrementor/incrementor.module';
import { LinkModule } from './link/link.module';
import { LocationInfoModule } from './location-info/location-info.module';
import { LogoModule } from './logo/logo.module';
import { OloMenuModule } from './olo-menu/olo-menu.module';
import { MultiTextFieldsModule } from './multi-text-fields/multi-text-fields.module';
import { NavbarModule } from './navbar/navbar.module';
import { OrderInfoModule } from './order-info/order-info.module';
import { PillModule } from './pill/pill.module';
import { ProductCardModule } from './product-card/product-card.module';
import { RadioButtonModule } from './radio-button/radio-button.module';
import { SearchBarModule } from './search-bar/search-bar.module';
import { SingleTextFieldModule } from './single-text-field/single-text-field.module';
import { SubNavModule } from './sub-nav/sub-nav.module';
import { SavedProductCardModule } from '@lib/lib/saved-product-card/saved-product-card.module';
// import { OloKioskMenuModule } from '@app/components/kiosk-expo/kiosk/olo-menu/olo-menu.module';

@NgModule({
  declarations: [ChepriModulesComponent],
  imports: [
    AuthNavModule,
    BagModule,
    BagItemModule,
    ButtonModule,
    CartNavModule,
    HeaderModule,
    IconModule,
    IncrementorModule,
    LinkModule,
    LocationInfoModule,
    LogoModule,
    OloMenuModule,
    MultiTextFieldsModule,
    NavbarModule,
    OrderInfoModule,
    PillModule,
    ProductCardModule,
    RadioButtonModule,
    SearchBarModule,
    SingleTextFieldModule,
    SubNavModule,
    SavedProductCardModule
    // OloKioskMenuModule
  ],
  exports: [ChepriModulesComponent]
})
export class ChepriModulesModule {}
