import { IDirectusItem } from '@app/models/capacity.model';

export interface IDirectusExpoOrderList {
  data: IDirectusExpoOrder[];
}

export interface IDirectusExpoOrder extends IDirectusItem {
  check_in_time: string;
  customer_name: string;
  fired_time: string;
  location: string;
  order_id: string;
  order_reference: string;
  order_status: string;
  order_type: string;
  party_size: string;
  ready_time: string;
  table_number: string;
  table_preference: string;
  is_kiosk: boolean;
  invoice_number: string;
  is_refunded: boolean;
  card_type: string;
  card_number: string;
  auth_code: string;
  record_number: string;
  ref_number: string;
  refund_card_type: string;
  refund_card_number: string;
  refund_auth_code: string;
  refund_ref_number: string;
}
