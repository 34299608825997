import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ModeService } from '@app/providers/mode.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { GuestInfoModalComponent } from '@app/components/guest-info-modal/guest-info-modal.component';
import { BillingInfo } from 'src/assets/chepri-modules/src/models/olo.billinginfo';
import {
  SetBillingInfo,
  EmptyPreviousBasket,
  CreateBasket,
  GetTimeSlots,
  SetHandoffMode
} from '@app/store/actions/basket.action';
import { Select, Store } from '@ngxs/store';
import { SetPartySize } from '@app/store/actions/user.action';
import { SoldoutModalComponent } from 'src/assets/chepri-modules/src/lib/soldout-modal/soldout-modal.component';
import { GetMenu, GetRestaurantCapacity, GetCalendars } from '@app/store/actions/restaurant.action';
import { DirectusUserService } from '@app/providers/expo/directus/directus-user.service';
import { Router } from '@angular/router';
import { TrancloudProviderService } from '@app/datacap-trancloud/services/trancloud-provider.service';
import { DirectusExpoProvider } from '@app/providers/expo/directus/directus-expo.provider';
import { FormBuilder } from '@angular/forms';
import { DirectusCurrentOrder, SelectableTime, TimeSlot } from '@app/models/capacity.model';
import { WebSocketsService } from '@app/providers/expo/websockets/websockets.service';
import { interval, Observable, Subject, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { CapacityService } from '@app/providers/capacity.service';
import * as moment from 'moment';
import { DirectusApiService } from '@app/providers/expo/directus/directus-api.service';
import { HeatmapLocationInterface } from '@app/models/heatmap-location.interface';
import { RestaurantService } from '@app/providers/restaurant.service';
import { DateTime } from '../../../../../assets/chepri-modules/src/models/DateTime';
import { DirectusProvider } from '@app/providers/directus.provider';
import { take } from 'rxjs/operators';
import { CmsService } from '@app/providers/cms.service';
import { DailyCapacity } from '@app/models/directus.collections';
import { environment } from '@env/environment';
import { GlobalStateModel } from '@app/store/state.model';
import { DirectusSchema, DirectusUsers } from '@app/providers/expo/directus/directus-collections.interface';
import { DirectusUser } from '@directus/sdk';
import { AsyncPipe } from '@angular/common';
const later = require('@breejs/later');

@Component({
  selector: 'app-kiosk-expo-start-order',
  templateUrl: './start-order.component.html',
  styleUrls: ['./start-order.component.scss'],
  providers: [AsyncPipe]
})
export class StartOrderComponent implements OnDestroy {
  availableLocations: HeatmapLocationInterface[] = [];
  selectedLocationId: string = 'ALL_LOCATIONS';
  selectedHeatmapLocation: HeatmapLocationInterface | null = null;
  isLoading: string;
  hasScrolled = false;
  subscriptions: Subscription[] = [];
  @Select((state: any) => state.user.isMaster) isMaster$: Observable<number>;

  private intervalSubject: Subject<void>;

  constructor(
    private router: Router,
    private modeService: ModeService,
    private modalService: NgbModal,
    private store: Store,
    private directusUser: DirectusUserService,
    private directusExpo: DirectusExpoProvider,
    private directusApi: DirectusApiService,
    private webSocket: WebSocketsService,
    private capacity: CapacityService,
    private restaurant: RestaurantService,
    private directus: DirectusProvider,
    private cms: CmsService,
    private cdr: ChangeDetectorRef,
    private asyncPipe: AsyncPipe
  ) {
    this.modeService.setApplicationMode('kms');
    this.intervalSubject = new Subject<void>();

    this.initializeComponent();
  }

  /**
   * Initializes the component by fetching user data and setting up heatmap locations.
   */
  private initializeComponent(): void {
    this.directusUser.getCurrentUser(this.directusExpo.getStoredDirectusID()).subscribe(user => {
      const finishInit = (usersData: DirectusUser<DirectusSchema>[]) => {
        console.log('usersData', usersData);
        this.availableLocations = usersData.map(userData => this.createHeatmapLocation(userData));

        if (this.availableLocations.length > 0) {
          this.selectedLocationId = this.availableLocations[0].restaurantID;
          this.loadHeatmapLocation(this.availableLocations[0]);
        }
      };

      if (user.role === '07fbb946-5744-44d6-b805-302fd48b261f') {
        // Master role ID
        this.directusApi.getUsers().subscribe(users => {
          const filteredUsers = users.filter(x => x.location_olo_id !== null && x.location_olo_id !== '0');
          finishInit(filteredUsers);
        });
      } else {
        finishInit([user]);
      }
    });
  }

  /**
   * Transforms Directus user data into HeatmapLocationInterface objects.
   * @param userData The Directus user data.
   * @returns A HeatmapLocationInterface object.
   */
  private createHeatmapLocation(userData: DirectusUser<DirectusSchema>): HeatmapLocationInterface {
    if (Number(userData.role as string) === 1) {
      // Admin
      return {
        restaurantID: '0',
        locationRestaurantId: 0,
        capacityBuckets: [],
        timeStamps: [],
        locationName: `${userData.first_name}${userData.last_name.trim() ? ' ' + userData.last_name : ''}`,
        nextHourQuant: 0,
        restOfDayQuant: 0,
        upcomingSlots: [],
        showAsMaster: false,
        gfLeft: 0
      };
    } else {
      return {
        restaurantID: userData.location_olo_id,
        locationRestaurantId: parseInt(userData.location_restaurant_id, 10),
        capacityBuckets: [],
        timeStamps: [],
        locationName: `${userData.first_name} ${userData.last_name}`,
        nextHourQuant: 0,
        restOfDayQuant: 0,
        upcomingSlots: [],
        showAsMaster: userData.show_as_master,
        gfLeft: 0
      };
    }
  }

  /**
   * Loads and initializes data for the selected heatmap location.
   * @param location The selected HeatmapLocationInterface object.
   */
  private loadHeatmapLocation(location: HeatmapLocationInterface): void {
    // Clear existing subscriptions to prevent memory leaks
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];

    // Initialize the selectedHeatmapLocation
    this.selectedHeatmapLocation = { ...location, capacityBuckets: [], timeStamps: [], upcomingSlots: [] };

    // Fetch and dispatch necessary data
    const menuSub = this.store.dispatch(new GetMenu(location.restaurantID.toString())).subscribe(res => {
      const restaurantId = res.restaurant.restaurant.id;
      const capacitySub = this.store.dispatch(new GetRestaurantCapacity(restaurantId)).subscribe(() => {
        const calendarsSub = this.store.dispatch(new GetCalendars(restaurantId, false)).subscribe(calres => {
          this.setCapacityBuckets(calres, restaurantId, this.selectedHeatmapLocation!);

          // Set interval based on user role
          const intervalTime = this.asyncPipe.transform(this.isMaster$) ? 30000 : 5000;
          const intervalSub = interval(intervalTime).subscribe(() => {
            this.setCapacityBuckets(calres, restaurantId, this.selectedHeatmapLocation!);
          });
          this.subscriptions.push(intervalSub);
        });
        this.subscriptions.push(calendarsSub);
      });
      this.subscriptions.push(capacitySub);
    });
    this.subscriptions.push(menuSub);
  }

  /**
   * Sets the capacity buckets and related data for the selected heatmap location.
   * @param calres The calendar response.
   * @param id The restaurant ID.
   * @param location The selected HeatmapLocationInterface object.
   */
  private setCapacityBuckets(calres: any, id: string, location: HeatmapLocationInterface): void {
    console.log('running setCapacityBuckets');
    this.cdr.detectChanges();

    const ordersSub = this.capacity.getOrders(id, false).subscribe((orders: any[]) => {
      // Set Daily and Hourly Sales
      this.setHourandDailySales(orders, location);

      // Set Heatmap
      this.setHeatmapSliderTiles(orders, id, location, calres);

      // Check Enzo Availability
      const enzoSub = this.capacity
        .checkEnzoAvailability(Number(id))
        .pipe(take(1))
        .subscribe((amountLeft: number) => {
          location.gfLeft = amountLeft;
        });
      this.subscriptions.push(enzoSub);

      // Set Next available times
      const masterSub = this.isMaster$.subscribe(isMaster => {
        if (isMaster) {
          this.cms.getLocation(location.restaurantID).subscribe(loc => {
            this.restaurant.getCalendars(location.restaurantID).subscribe(res => {
              const end = res.calendar[0].ranges[0].end;
              location.upcomingSlots = this.capacity.getNextAvailableTimes(loc, end, orders, false);
            });
          });
        } else {
          location.upcomingSlots = this.capacity.getNextAvailableTimes(
            calres.restaurant.capacity_settings,
            calres.restaurant.calendar.calendar[0].ranges[0].end,
            orders,
            false
          );
          console.log('selectedHeatmapLocation.upcomingSlots', location.upcomingSlots);
        }
      });
      this.subscriptions.push(masterSub);
    });

    this.subscriptions.push(ordersSub);
  }

  /**
   * Sets the heatmap slider tiles based on orders and capacity data.
   * @param orders The list of current orders.
   * @param id The restaurant ID.
   * @param location The selected HeatmapLocationInterface object.
   * @param state The current state from GetCalendars action.
   */
  private setHeatmapSliderTiles(orders: any[], id: string, location: HeatmapLocationInterface, state: any): void {
    const masterSub = this.isMaster$.subscribe(isMaster => {
      if (isMaster) {
        this.cms.getLocation(location.restaurantID).subscribe(loc => {
          this.restaurant.getCalendars(location.restaurantID).subscribe(res => {
            const end = res.calendar[0].ranges[0].end;
            location.capacityBuckets = this.capacity.getHeatmapData(loc, end, orders);
            this.populateTimeStamps(location);
          });
        });
      } else {
        location.capacityBuckets = this.capacity.getHeatmapData(
          state.restaurant.capacity_settings,
          state.restaurant.calendar.calendar[0].ranges[0].end,
          orders
        );
        console.log('selectedHeatmapLocation.capacityBuckets', location.capacityBuckets);
        this.populateTimeStamps(location);
      }
    });
    this.subscriptions.push(masterSub);
  }

  /**
   * Populates the time stamps for the heatmap based on capacity buckets.
   * @param location The selected HeatmapLocationInterface object.
   */
  private populateTimeStamps(location: HeatmapLocationInterface): void {
    location.timeStamps = [];
    location.capacityBuckets.forEach((slot: any, index: number) => {
      if (index === 0 || Number.isInteger(index / 3)) {
        location.timeStamps.push(slot.time.moment.format());
      }
    });
  }

  /**
   * Sets the hourly and daily sales based on current orders.
   * @param orders The list of current orders.
   * @param location The selected HeatmapLocationInterface object.
   */
  private setHourandDailySales(orders: any[], location: HeatmapLocationInterface): void {
    let dailyQuant = 0;
    let nextHourQuant = 0;
    orders.forEach(order => {
      const readyTime = moment(order.ready_time);
      if (readyTime.isBefore(moment().add(1, 'h'))) {
        nextHourQuant += order.pizza_quant;
      }
      dailyQuant += order.pizza_quant;
    });
    location.restOfDayQuant = dailyQuant;
    location.nextHourQuant = nextHourQuant;
  }

  /**
   * Handles the change event when a different location is selected.
   * @param event The change event.
   */
  locationSelectChange(event: any): void {
    this.selectedLocationId = event.target.value;
    const newLocation = this.availableLocations.find(loc => loc.restaurantID === this.selectedLocationId);
    if (newLocation) {
      this.loadHeatmapLocation(newLocation);
    }
  }

  /**
   * Opens the guest information modal for order initiation.
   * @param type The type of order ('dinein' or 'pickup').
   * @param locationIndex The index of the selected heatmap location.
   */
  guestInfoModal(type: string, location: HeatmapLocationInterface): void {
    const guestInfoModal = this.modalService.open(GuestInfoModalComponent, {
      centered: true,
      keyboard: false,
      backdrop: 'static'
    });
    guestInfoModal.componentInstance.type = type;
    guestInfoModal.componentInstance.xClicked.subscribe(() => {
      this.modalService.dismissAll();
    });
    guestInfoModal.componentInstance.startOrderClicked.subscribe((guestInfo: any) => {
      const billingInfo = new BillingInfo();
      billingInfo.firstname = guestInfo.firstName;
      billingInfo.lastname = guestInfo.lastName;
      billingInfo.emailaddress = environment.kioskDefaultEmail;
      billingInfo.contactnumber = environment.kioskDefaultPhone;
      billingInfo.usertype = 'guest';
      this.store.dispatch(new SetBillingInfo(billingInfo)).subscribe(() => {
        if (guestInfo.partySize) {
          this.store.dispatch(new SetPartySize(guestInfo.partySize + 1));
        }
        this.startOrder(type, location.restaurantID);
      });
    });
  }

  /**
   * Initiates the order process based on the order type and restaurant ID.
   * @param orderType The type of order ('pickup' or 'dinein').
   * @param restaurantID The ID of the restaurant.
   */
  startOrder(orderType: string, restaurantID: string): void {
    this.store.dispatch(new EmptyPreviousBasket());
    if (orderType === 'pickup') {
      this.store.dispatch(new CreateBasket(restaurantID.toString())).subscribe(res => {
        this.store.dispatch(new GetCalendars(res.restaurant.restaurant.id, false)).subscribe(calRes => {
          this.store.dispatch(new GetTimeSlots(true)).subscribe(slotRes => {
            if (slotRes.basket.timeSlots.length === 0) {
              this.openSoldOutModal();
            } else {
              this.router.navigateByUrl(`/kiosk/menu/${restaurantID}`);
              this.modalService.dismissAll();
            }
          });
        });
      });
    } else if (orderType === 'dinein') {
      this.store.dispatch(new CreateBasket(restaurantID.toString())).subscribe(res => {
        this.store.dispatch(new SetHandoffMode('dinein'));
        this.store.dispatch(new GetCalendars(res.restaurant.restaurant.id, false)).subscribe(calRes => {
          this.store.dispatch(new GetTimeSlots(true)).subscribe(slotRes => {
            if (slotRes.basket.timeSlots.length === 0) {
              this.openSoldOutModal();
            } else {
              this.router.navigateByUrl(`/kiosk/menu/${restaurantID}`);
              this.modalService.dismissAll();
            }
          });
        });
      });
    }
  }

  /**
   * Opens the sold-out modal when no time slots are available.
   */
  openSoldOutModal(): void {
    const modalOptions: NgbModalOptions = { centered: true, size: 'lg', windowClass: 'hideScroll' };
    const modalRef = this.modalService.open(SoldoutModalComponent, modalOptions);
    modalRef.componentInstance.xClicked.subscribe(() => {
      this.modalService.dismissAll();
    });
    modalRef.componentInstance.continueClicked.subscribe(() => {
      this.router.navigateByUrl('/menu');
      this.modalService.dismissAll();
    });
  }

  /**
   * Handles the scroll event on the ribbon to determine if scrolling has occurred.
   * @param event The scroll event.
   */
  ribbonScroll(event: Event): void {
    const el = event.target as HTMLElement;
    this.hasScrolled = el.scrollLeft > 0;
  }

  /**
   * Scrolls the ribbon back to the start.
   */
  scrollBack(): void {
    const el = document.getElementById('ribbon-scroll');
    if (el) {
      el.scrollLeft = 0;
    }
  }

  /**
   * Cleans up subscriptions to prevent memory leaks.
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
    if (this.intervalSubject) {
      this.intervalSubject.complete();
    }
  }
}
