import { NgModule } from '@angular/core';
import { MultiTextFieldsComponent } from './multi-text-fields.component';
import { CommonModule } from '@angular/common';
import { SingleTextFieldModule } from '../single-text-field/single-text-field.module';

@NgModule({
  declarations: [MultiTextFieldsComponent],
  imports: [CommonModule, SingleTextFieldModule],
  exports: [MultiTextFieldsComponent]
})
export class MultiTextFieldsModule {}
