<ng-container *ngIf="svgLocation">
  <div id="ex4">
    <i [ngClass]="{ p1: badgeAmount }" [attr.data-count]="badgeAmount">
      <img
        [src]="svgLocation"
        [alt]="altText"
        [height]="svgHeight"
        [width]="svgWidth"
        [ngStyle]="{ filter: grayScaleStyle }"
      />
    </i>
  </div>
</ng-container>
<ng-container *ngIf="!svgLocation && iconName">
  <div id="ex4" [ngStyle]="{ 'font-size': iconSize, filter: grayScaleStyle }">
    <i class="fa fa-{{ iconName }}" [ngClass]="{ p1: badgeAmount }" [attr.data-count]="badgeAmount"></i>
  </div>
</ng-container>
<ng-container *ngIf="!svgLocation && !iconName">
  <div id="ex4" [ngStyle]="{ 'font-size': '40px', color: 'black' }">
    <i class="fa fa-question-circle"></i>
  </div>
</ng-container>
