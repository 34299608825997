import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ui-header',
  template: `
    <p style="color: #FFF">
      header works!
    </p>
  `,
  styles: []
})
export class HeaderComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
