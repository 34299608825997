import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderInfoModule } from '../order-info/order-info.module';
import { BagItemModule } from '../bag-item/bag-item.module';
import { ButtonModule } from '../button/button.module';
import { LinkModule } from '../link/link.module';
import { MenuTemplateComponent } from './menu-template.component';
import { NavbarModule } from '../navbar/navbar.module';
import { HeaderHeroModule } from '../header-hero/header-hero.module';
import { OloMenuModule } from '../olo-menu/olo-menu.module';

@NgModule({
  declarations: [MenuTemplateComponent],
  imports: [
    CommonModule,
    HeaderHeroModule,
    OrderInfoModule,
    BagItemModule,
    ButtonModule,
    LinkModule,
    NavbarModule,
    HeaderHeroModule,
    OloMenuModule
  ],
  exports: [MenuTemplateComponent]
})
export class MenuTemplateModule {}
