import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderInfoModule } from '../order-info/order-info.module';
import { BagItemModule } from '../bag-item/bag-item.module';
import { ButtonModule } from '../button/button.module';
import { LinkModule } from '../link/link.module';
import { LocationsTemplateComponent } from './locations-template.component';
import { NavbarModule } from '../navbar/navbar.module';
import { HeaderHeroModule } from '../header-hero/header-hero.module';
import { OloMenuModule } from '../olo-menu/olo-menu.module';
import { LocationsModule } from '../locations/locations.module';

@NgModule({
  declarations: [LocationsTemplateComponent],
  imports: [
    CommonModule,
    OrderInfoModule,
    BagItemModule,
    ButtonModule,
    LinkModule,
    NavbarModule,
    HeaderHeroModule,
    OloMenuModule,
    LocationsModule
  ],
  exports: [LocationsTemplateComponent]
})
export class LocationsTemplateModule {}
