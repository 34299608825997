import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store, Select } from '@ngxs/store';
import { GetRecentOrders } from '@app/store/actions/user.action';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { UserStateModel, UserState } from '@app/store/state/user.state';
import { FormatService } from '../../providers/format.service';
import { DateTime } from '../../models/DateTime';
import { orderstatus } from '../../models/olo.recentorders';
import { CreateFromOrder } from '@app/store/actions/basket.action';
import { Router } from '@angular/router';
import { RestaurantState } from '@app/store/state/restaurant.state';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'lib-past-orders',
  templateUrl: './past-orders.component.html',
  styles: []
})
export class PastOrdersComponent implements OnInit, OnDestroy {
  @Select() user$: Observable<UserStateModel>;
  @Input() buttons = true;
  @Input() hasPastOrders: boolean;
  @Input() basketCreated = true;
  @Input() orderText = 'order';
  @Input() dropdownChildren = ['indivdual', 'group', 'catering'];
  @Input() includesBafala = true;
  closeResult: string;
  selectedOrder: orderstatus;
  subs: any = [];
  modalReference: NgbModalRef;
  images: any;
  @Input() pastOrders: orderstatus[];

  constructor(
    private modalService: NgbModal,
    private store: Store,
    private format: FormatService,
    private router: Router
  ) {
    this.images = [];
  }

  ngOnInit() {
    this.store.dispatch(new GetRecentOrders());
    this.setImages();
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
  }
  clickEvent(event: any) {}

  setImages() {
    const images = this.store.selectSnapshot(RestaurantState).images.data;
    const pastOrders = this.store.selectSnapshot(UserState).recentOrders.orders;
    console.log('pastOrders', pastOrders);
    for (const order of pastOrders) {
      for (const image of images) {
        if (image.name === order.products[0].name) {
          this.images.push(environment.cmsDomain.concat('assets/', image.image.id));
        }
      }
    }
    // console.log('this images', this.images);
  }

  pastOrderExpand(pastOrderPopup: any, order: orderstatus) {
    this.selectedOrder = order;
    this.modalReference = this.modalService.open(pastOrderPopup, { ariaLabelledBy: 'pastOrderModal', centered: true });
    this.modalReference.result.then(
      (result: any) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason: any) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  parseDate(oloDateTime: string): string {
    const dateTime = DateTime.fromOlo(oloDateTime);
    if (!!dateTime && dateTime.shortDateString) {
      return dateTime.shortDateString;
    }
    return null;
  }

  handleReorder(order?: orderstatus) {
    // Get current selected order,
    // Dispatch call to Olo to create basket from previous order
    // Navigate to order details page to begin checkout flow
    // const orderId = this.pastOrders[ this.selectedOrder ].id;

    // If order is provided, set selected
    if (order) {
      this.selectedOrder = order;
    }
    const token: string = this.store.selectSnapshot(state => state.user.info.authtoken);
    const orderId = this.selectedOrder.id;
    const orderRef = this.selectedOrder.orderref;
    this.subs.push(
      this.store.dispatch(new CreateFromOrder(orderRef, orderId, token)).subscribe(res => {
        if (res) {
          if (this.modalReference) {
            this.modalReference.dismiss();
          }
          this.router.navigateByUrl('/order-details');
        }
      })
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
