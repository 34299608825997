import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-chepri-modules',
  template: `
    <p>
      chepri-modules works!
    </p>
  `,
  styles: []
})
export class ChepriModulesComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
