import { Component, Input, Output, EventEmitter } from '@angular/core';
import { orderstatus } from '../../models/olo.recentorders';

@Component({
  selector: 'lib-past-order-modal',
  templateUrl: './past-order-modal.component.html',
  styles: []
})
export class PastOrderModalComponent {
  @Output() reorder = new EventEmitter<any>();
  @Output() xClicked = new EventEmitter<any>();

  @Input() order: orderstatus;

  constructor() {}

  xClick() {
    this.xClicked.emit();
  }

  reorderClick() {
    this.reorder.emit();
  }
}
