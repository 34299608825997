import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'oloDate'
})
export class OloDatePipe implements PipeTransform {
  transform(oloDate: string): Date {
    return moment(oloDate, 'YYYYMMDD HH:mm').toDate();
  }
}
