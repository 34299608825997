export class CmsMessages {
  data: Message[]
  public: boolean
}

export class Message {
  id: number
  status: string
  sort: number
  created_by: number
  created_on: string
  title: string // Modal Title
  message_content: string // HTML Content to fill the modal
  guest_bypass: boolean // controls whether the guest can close the modal
  schedule_type: string // 1: Recurring, 2: Single Day, 3: Time Period
  days_shown: string[] // Recurring: Days of week shown
  date_shown: string // Single: Date to be shown YYYY-MM-DD
  start_date: string // Time Period: Start Date YYYY-MM-DD
  end_date: string // Time Period: End Date YYYY-MM-DD
  where: string // Where the message should be shown; 1: Locations Only, 2: Menu Only, 3: Both
  stage_only: boolean // For testing; Toggles whether the popup should show on production
}
