import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-multi-text-fields',
  templateUrl: './multi-text-fields.component.html',
  styles: []
})
export class MultiTextFieldsComponent {
  @Input() login: boolean;
  @Input() createAccount: boolean;
  @Input() userInfo: boolean;
  @Input() creditCardInfo: boolean;
  @Input() choice: string;

  constructor() {}
}
