import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lib-locations-template',
  templateUrl: './locations-template.component.html',
  styles: []
})
export class LocationsTemplateComponent implements OnInit {
  userLocation = { latitude: 39.981007, longitude: -83.0085312 };
  zoomLevel = 20;
  @Input() locations: [
    {
      name: 'Demo Location';
      streetaddress: '938 Dennison Ave.';
      city: 'Columbus';
      state: 'OH';
      zip: '43201';
      latitude: 22.33159;
      longitude: 105.63233;
      telephone: '(123) 123-4567';
      distance: '1.5 miles away';
    },
    {
      name: 'Demo Location';
      streetaddress: '938 Dennison Ave.';
      city: 'Columbus';
      state: 'OH';
      zip: '43201';
      latitude: 7.92658;
      longitude: -12.05228;
      telephone: '(123) 123-4567';
      distance: '1.5 miles away';
    },
    {
      name: 'Demo Location';
      streetaddress: '938 Dennison Ave.';
      city: 'Columbus';
      state: 'OH';
      zip: '43201';
      latitude: 48.75606;
      longitude: -118.859;
      telephone: '(123) 123-4567';
      distance: '1.5 miles away';
    },
    {
      name: 'Demo Location';
      streetaddress: '938 Dennison Ave.';
      city: 'Columbus';
      state: 'OH';
      zip: '43201';
      latitude: 5.19334;
      longitude: -67.03352;
      telephone: '(123) 123-4567';
      distance: '1.5 miles away';
    },
    {
      name: 'Demo Location';
      streetaddress: '938 Dennison Ave.';
      city: 'Columbus';
      state: 'OH';
      zip: '43201';
      latitude: 12.09407;
      longitude: 26.31618;
      telephone: '(123) 123-4567';
      distance: '1.5 miles away';
    },
    {
      name: 'Demo Location';
      streetaddress: '938 Dennison Ave.';
      city: 'Columbus';
      state: 'OH';
      zip: '43201';
      latitude: 47.92393;
      longitude: 78.58339;
      telephone: '(123) 123-4567';
      distance: '1.5 miles away';
    }
  ];

  @Input() links = [
    {
      text: 'home',
      url: 'https://www.google.com'
    },
    {
      text: 'locations',
      url: 'https://stackoverflow.com'
    },
    {
      text: 'menu',
      url: 'https://getbootstrap.com'
    },
    {
      text: 'Sign in',
      url: 'https://getbootstrap.com'
    }
  ];

  @Input() orderInfoTop = true;
  @Input() location = {
    name: 'Demo Location',
    streetaddress: '938 Dennison Ave.',
    city: 'Columbus',
    state: 'OH',
    zip: '43201',
    phone: '614-826-2673'
  };
  @Input() menuItem = 'CLASSIC PIZZAS';
  @Input() basket = {
    deliverymode: 'pickup', // can be 'pickup', 'dispatch', 'delivery'
    products: [
      {
        basecost: 10,
        choices: [
          {
            cost: 0,
            name: 'Mozzarella Cheese',
            quantity: 1
          },
          {
            cost: 1,
            name: 'Pepperoni',
            quantity: 1
          },
          {
            cost: 0,
            name: 'Red Onion',
            quantity: 1
          },
          {
            cost: 0,
            name: 'Portabella Mushroom',
            quantity: 2
          }
        ],
        name: 'Large Pizza',
        specialinstructions: 'Mushrooms only on half',
        totalcost: 11
      }
    ]
  };

  ngOnInit() {}

  addToBag(product: any) {
    // TODO
    console.log('Add to bag: ' + product.name);
  }

  customize(product: any) {
    // TODO
    console.log('Customize: ' + product.name);
  }
}
