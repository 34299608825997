export interface BusinessSettings {
  created_by: string;
  created_on: any;
  id: number;
  olo_key: string;
  olo_reference_menu_id: number;
  refresh_olo_menu: string;
  olo_settings: any;
}

export interface Categories {
  created_on: any;
  id: number;
  image: string;
  name: string;
  name_slug: string;
  slug: string;
  subtitle: string;
  title: string;
}

export interface CustomerKiosk {
  id: number;
  kiosk_available: boolean;
  kiosk_id: string;
  location: number | Locations;
  mac_ip_address: string;
  pin_pad_provider: string;
  pin_pad_type: string;
  printer_ip: string;
  use_mac_address: boolean;
}

export interface DailyCapacity {
  batch_timestamp: string;
  created_by: string;
  created_on: any;
  end_time: any;
  friday: number;
  friday_buzz: number;
  id: number;
  location_id: number;
  monday: number;
  monday_buzz: number;
  saturday: number;
  saturday_buzz: number;
  start_time: any;
  sunday: number;
  sunday_buzz: number;
  thursday: number;
  thursday_buzz: number;
  tuesday: number;
  tuesday_buzz: number;
  wednesday: number;
  wednesday_buzz: number;
}

export interface ErrorLog {
  date_created: any;
  error: string;
  id: number;
  location: number | Locations;
  response: string;
  tran_code: string;
}

export interface ExpoMetadata {
  auth_code: string;
  card_number: string;
  card_type: string;
  check_in_time: any;
  created_by: string;
  created_on: any;
  customer_name: string;
  fired_time: any;
  id: number;
  invoice_number: string;
  is_kiosk: boolean;
  is_refunded: boolean;
  location: number | Locations;
  order_id: string;
  order_reference: string;
  order_status: string;
  order_type: string;
  party_size: string;
  ready_time: any;
  record_number: string;
  ref_number: string;
  refund_auth_code: string;
  refund_card_number: string;
  refund_card_type: string;
  refund_ref_number: string;
  table_number: string;
  table_preference: string;
}

export interface KioskCoupons {
  coupon_code: string;
  coupon_name: string;
  created_on: any;
  id: number;
  owner: string;
  status: string;
}

export interface Locations {
  catering_number: string;
  disable_dinein: boolean;
  disable_takeout: boolean;
  friday: number;
  hours_of_operation: string;
  id: number;
  latitude: string;
  location_address_line_1: string;
  location_address_line_2: string;
  location_link: string;
  location_name: string;
  longitude: string;
  max_table_size: string;
  monday: number;
  olo_id: number;
  saturday: number;
  status: string;
  sunday: number;
  telephone: string;
  temporarily_closed: boolean;
  thursday: number;
  tuesday: number;
  wednesday: number;
  enzo_capacity: any;
  capacity_days: number[] | DailyCapacity[];
}

export interface MainSettings {
  buzz_tuning_email_address: string;
  checkout_timeout_length: number;
  created_by: string;
  created_on: any;
  default_image: string;
  enzo_modifier_name: string;
  gluten_free_name: string;
  id: number;
  main_logo: string;
  slogan: string;
  title: string;
}

export interface OrderMetaData {
  created_by: string;
  created_on: any;
  enzo_quant: number;
  id: number;
  is_olo_takeout: boolean;
  is_placeholder: boolean;
  location: number | Locations;
  oloid: string;
  pizza_quant: number;
  ready_time: any;
  salad_quant: number;
}

export interface OrderMetaDataLocations {
  id: number;
  locations_id: number | Locations;
  order_meta_data_id: number | OrderMetaData;
}

export interface Products {
  allergens: any;
  calories: number;
  calories_from_fat: number;
  carbohydrates: number;
  category: string;
  category_slug: string;
  cholesterol: number;
  dietary_fiber: number;
  grams_of_fat: number;
  hidden_on_nutrition: boolean;
  id: number;
  image: string;
  ingredients: string;
  menu_id: string;
  name: string;
  name_slug: string;
  protein: number;
  saturated_fats: number;
  serving_size: string;
  small_calories: string;
  small_calories_from_fat: string;
  small_carbohydrates: string;
  small_cholesterol: string;
  small_dietary_fiber: string;
  small_grams_of_fat: string;
  small_protein: string;
  small_saturated_fats: string;
  small_serving_size: string;
  small_sodium: string;
  small_sugars: string;
  small_trans_fats: string;
  sodium: number;
  sugars: number;
  trans_fats: string;
  nutrition_group: any;
  smalller_nutrition: any;
}

export interface ScheduledMessaging {
  created_by: string;
  created_on: any;
  date_shown: any;
  days_shown: any;
  end_date: any;
  guest_bypass: boolean;
  id: number;
  message_content: string;
  schedule_type: string;
  sort: number;
  start_date: any;
  status: string;
  title: string;
  where: string;
  content: any;
  location_shown: any;
  recurring: any;
  scheduling: any;
  single_day: any;
  time_period: any;
}

export interface DirectusUsers {
  card_readers: any;
  location_merchant_id: string;
  location_olo_id: string;
  location_restaurant_id: string;
  location_trancloud_id: string;
  printer_ip: any;
  show_as_master: boolean;
}

export type DirectusSchema = {
  business_settings: BusinessSettings[];
  categories: Categories[];
  customer_kiosk: CustomerKiosk[];
  daily_capacity: DailyCapacity[];
  error_log: ErrorLog[];
  expo_metadata: ExpoMetadata[];
  kiosk_coupons: KioskCoupons[];
  locations: Locations[];
  main_settings: MainSettings[];
  order_meta_data: OrderMetaData[];
  order_meta_data_locations: OrderMetaDataLocations[];
  products: Products[];
  scheduled_messaging: ScheduledMessaging[];
  directus_users: DirectusUsers[];
};

export type DirectusCollection =
  | BusinessSettings
  | Categories
  | CustomerKiosk
  | DailyCapacity
  | ErrorLog
  | ExpoMetadata
  | KioskCoupons
  | Locations
  | MainSettings
  | OrderMetaData
  | OrderMetaDataLocations
  | Products
  | ScheduledMessaging
  | DirectusUsers;
